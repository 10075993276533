import React from 'react';
import { PageProps } from 'gatsby';
import { WithTranslation, withTranslation } from 'react-i18next';

import Layout, { HeroSectionColors, LayoutOptions } from '../../../components/Layout/Layout';

import { IconTypes } from '../../../components/Icon/Icon';
import InfoBlock from '../../../components/InfoBlock/InfoBlock';
import { ButtonLinkSize } from '../../../components/ButtonLink/ButtonLink';
import { LocaleNameSpaceKeys } from '../../../localization/translations';
import { getPathFromRouteKey } from '../../../shared/utils/getPathFromRouteKey';
import RouteKeys from '../../../../config/RouteKeys';

import './style.scss';

type Props = PageProps & WithTranslation;

const Programs: React.FC<Props> = (props) => {
  const { t } = props;

  const layoutOptions: LayoutOptions = {
    seo: {
      title: t('programs.seo.title'),
    },
    page: {
      title: t('programs.heading'),
    },
    heroSection: {
      options: {
        accentColor: HeroSectionColors.SKY_BLUE,
        heroImage: {
          fileName: 'hero-image-13.jpg',
          alt: 'Image',
        },
      },
    },
  };

  return (
    <Layout options={layoutOptions} className="Programs">
      <div className="grid-container">
        <div className="row row-wrap">
          <aside className="column column-10" />
          <section className="column column-80">
            <InfoBlock
              buttonOptions={{
                to: getPathFromRouteKey(RouteKeys.CUSTOMER_CENTRE.HOME_WARRANTY),
                label: t(`programs.buttonLabel`),
                iconOptions: {
                  icon: IconTypes.ARROW_NEXT,
                },
                styleOptions: {
                  isInline: true,
                  size: ButtonLinkSize.LARGE,
                },
                ariaLabel: t('customerCentre.encompass.ariaButtonLabel'),
              }}
              heading={<h2 className="h5">{t('customerCentre.encompass.heading')}</h2>}
            >
              <p>{t('customerCentre.encompass.body')}</p>
            </InfoBlock>
            <InfoBlock
              buttonOptions={{
                to: getPathFromRouteKey(RouteKeys.CUSTOMER_CENTRE.NEXTJOB_PROGRAM),
                label: t(`programs.buttonLabel`),
                iconOptions: {
                  icon: IconTypes.ARROW_NEXT,
                },
                styleOptions: {
                  isInline: true,
                  size: ButtonLinkSize.LARGE,
                },
                ariaLabel: t('customerCentre.nextJob.ariaButtonLabel'),
              }}
              heading={<h2 className="h5">{t(`customerCentre.nextJob.heading`)}</h2>}
            >
              <p>{t(`customerCentre.nextJob.body`)}</p>
            </InfoBlock>
          </section>
          <aside className="column column-10" />
        </div>
      </div>
    </Layout>
  );
};

export default withTranslation(LocaleNameSpaceKeys.CUSTOMER_CENTRE)(Programs);
